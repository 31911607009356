<div class="progress-bar-container">
  <div class="shape-container">
    <div class="bar-container">
      <div
        [style.background-color]="
          config().percent >= config().benchmark ? '#2ecc7140' : '#e6292940'
        "
        class="progress"
      >
        <div
          [style.background-color]="
            config().percent >= config().benchmark ? '#2ecc71' : '#e62929'
          "
          [style.width]="config().percent + '%'"
          class="progress-bar"
        ></div>
      </div>
    </div>
    <div class="percent">{{ config().percent.toFixed(2) + "%" }}</div>
  </div>
</div>
